import React, {useEffect, useState} from 'react';
import './OfferButton.css';
import {OwnProps} from './types';
import Icon from '../../../Icon';

function OfferButton({onClick, labael}: OwnProps) {
  return (
    <div className="div-offer-button" onClick={() => onClick()}>
      <span className="text-offer-button">{labael}</span>
      <Icon iconName="right-arrow" width={22} iconColor="#FFFFFF" />
    </div>
  );
}

export default OfferButton;
