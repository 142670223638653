/* eslint-disable import/no-anonymous-default-export */
import {CombinedState, combineReducers, PayloadAction} from '@reduxjs/toolkit';

// Import reducers
import {RootState} from 'StoreTypes';
import crossSell from './crossSell';
import flights from './flights';
import offersTips from './offersTips';
import layouts from './layouts';

export const reducer = combineReducers({
  // Combine reducers
  crossSell,
  flights,
  offersTips,
  layouts,
});

export default (
  state: RootState,
  action: PayloadAction<{modules: string[]}>,
): CombinedState<RootState> => {
  return reducer(state, action);
};
