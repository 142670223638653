/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './InputSearch.css';
import headerLogo from '../../../assets/logo-voopter.svg';
import {FiX, FiSearch} from 'react-icons/fi';
import {PiAirplaneTiltFill} from 'react-icons/pi';
import {SlLocationPin} from 'react-icons/sl';
import * as diacritics from 'diacritics';
import Fuse from 'fuse.js';
import {OwnProps, airportPros} from './types';
import data from './airports.json';
import * as IconLibrary from 'react-icons/fi';
import Icon from '../../../Icon';
import ViewportChecker from '../../../../core/helpers/ViewPort';

function InputSearch({
  label,
  isError,
  placeholder,
  initialValue,
  onClick,
  onClear,
  onSelectedItem,
  isDesableSearchResults,
  isMobileModal,
  isReadOnly,
  inputIcon,
  colorIcon,
  value,
  autoFocus,
  onSuggestions,
  onWidth,
  isDeparture,
  isSelected,
}: OwnProps) {
  const IconComponent = IconLibrary[inputIcon as keyof typeof IconLibrary];

  const [inputValue, setInputValue] = useState<string>('');
  const [initialInputValue, setInputInitialValue] = useState<string | null>(
    initialValue ?? null,
  );
  const [isFocused, handlerFocused] = useState<boolean>(false);
  const [isHovered, handlerHovered] = useState<boolean>(false);
  const [airports] = useState<airportPros[] | []>(data);
  const [suggestions, setSuggestions] = useState<airportPros[] | []>([]);
  const [isSelectedAirport, handlerSelectedAirport] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const divRef = useRef<HTMLInputElement | null>(null);
  const divInputRef = useRef<HTMLInputElement | null>(null);

  const isMobileViewPort = ViewportChecker();

  const updateWidth = () => {
    if (divInputRef.current) {
      onWidth && onWidth(divInputRef.current.offsetWidth);
    }
  };
  useEffect(() => {
    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const removeAccents = (str: string) => {
    return diacritics.remove(str);
  };

  function escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  useEffect(() => {
    if (initialValue) {
      const initialItemValue =
        data.filter(item => item.airport_code === initialValue) ?? null;
      initialItemValue.length > 0 && selectedAirport(initialItemValue[0]);
    }
  }, [initialValue]);

  useEffect(() => {
    const inputValueTrim = inputValue.trimEnd();

    if (inputValueTrim) {
      const escapedInputValue = escapeRegExp(inputValueTrim);
      const regex = new RegExp(`^${removeAccents(escapedInputValue)}`, 'i');

      let filtered = airports
        .filter(
          (item: airportPros) =>
            regex.test(removeAccents(item.city_name)) ||
            regex.test(removeAccents(item.airport_name)),
        )
        .sort((a: airportPros, b: airportPros) => {
          if (a.is_metropolitan_area && !b.is_metropolitan_area) return -1;
          if (!a.is_metropolitan_area && b.is_metropolitan_area) return 1;
          return (
            a.city_name.localeCompare(b.city_name) ||
            a.airport_name.localeCompare(b.airport_name)
          );
        });

      // Verificar se o inputValue corresponde a um código IATA
      if (inputValueTrim.length === 3) {
        const iataMatch = data.find(
          item =>
            item.airport_code.toLowerCase() === inputValueTrim.toLowerCase(),
        );
        if (iataMatch) {
          // Remova o item correspondente da lista filtrada (se ele estiver lá)
          filtered = filtered.filter(
            item => item.airport_code !== iataMatch.airport_code,
          );
          // Insira o item correspondente no início da lista filtrada
          filtered.unshift(iataMatch);
        }
      }

      const result = [];
      let count = 0;

      for (let i = 0; i < filtered.length && count < 15; i++) {
        const item = filtered[i];
        if (item.is_metropolitan_area) {
          result.push(item);
          const subItems = filtered.filter(
            subItem =>
              subItem.metropolitan_area_code === item.airport_code &&
              subItem.city_name_is_english === item.city_name_is_english,
          );
          result.push(...subItems);
          count++;
        } else if (
          !filtered.find(
            subItem => subItem.metropolitan_area_code === item.airport_code,
          )
        ) {
          result.push(item);
          count++;
        }
      }

      const resultDuplicateFilter = result.filter(
        (obj, index, self) =>
          self.findIndex(o => o.airport_code === obj.airport_code) === index,
      );

      setSuggestions(resultDuplicateFilter);

      if (result.length <= 0) {
        forceComparationSearch();
      }
    } else {
      setSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, data]);

  useEffect(() => {
    onSuggestions && onSuggestions(suggestions.length > 0);
  }, [suggestions]);

  const selectedAirport = (item: airportPros) => {
    setInputValue(
      `${item.city_name} - ${item.airport_name} (${item.airport_code}), ${item.country_name}`,
    );
    onSelectedItem && onSelectedItem(item);

    handlerSelectedAirport(true);
  };

  const forceComparationSearch = () => {
    const options = {
      keys: ['city_name'],
      threshold: 0.5,
    };

    const fuse = new Fuse(airports, options);

    const searchTerm = inputValue;

    const result: any[] = fuse.search(searchTerm);

    // console.log(result.slice(0, 15));

    const newResult: any = [];

    result.slice(0, 10).map(item => {
      newResult.push(airports[item.refIndex]);
    });

    setSuggestions(newResult);
  };

  const onClearInput = () => {
    setInputValue('');
    inputRef.current?.focus();
    handlerSelectedAirport(false);
    onClear && onClear();
  };

  const isInputFocused = () => {
    if (inputRef.current === document.activeElement) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickOutside = (e: any) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      handlerSelectedAirport(true);
    }
  };

  useEffect(() => {
    // Adicionar um ouvinte de eventos quando o componente é montado
    document.addEventListener('mousedown', handleClickOutside);

    // Remover o ouvinte de eventos quando o componente é desmontado
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    handlerFocused(true);
    handlerSelectedAirport(false);

    // Ajusta a rolagem para evitar o empurrão
    if (isMobileModal) {
      setTimeout(() => {
        inputRef.current &&
          window.scrollTo({
            top: inputRef.current.offsetTop,
            behavior: 'smooth',
          });
      }, 300); // ajuste o tempo conforme necessário
    }
  };

  return (
    <div
      ref={divInputRef}
      onMouseEnter={() => !isMobileViewPort && handlerHovered(true)}
      onMouseLeave={() => !isMobileViewPort && handlerHovered(false)}
      style={{
        borderTopLeftRadius: isDeparture ? 10 : 0,
        borderBottomLeftRadius: isDeparture ? 10 : 0,
        backgroundColor:
          isHovered && !isSelected
            ? '#E0E0E0'
            : isSelected
            ? '#D2EFFF'
            : 'transparent',
      }}
      className={isError ? 'div-content-search-error' : 'div-content-search'}
      onClick={() => {
        inputRef.current?.focus();
        onClick && onClick();
      }}>
      {/* <div className="label-input-search">{label}</div> */}

      <div
        className={
          isMobileModal ? 'div-input-search-mobile' : 'div-input-search'
        }>
        {inputIcon && (
          <div className="div-icon-search">
            <Icon iconName={inputIcon} iconColor={colorIcon} />
          </div>
        )}

        <input
          autoFocus={autoFocus}
          ref={inputRef}
          value={value || inputValue}
          className={
            isMobileModal ? 'input-search-mobile' : 'input-search-mobile'
          }
          placeholder={placeholder}
          onChange={text => {
            setInputValue(text.target.value);
            // handlerFocused(true);
            handlerSelectedAirport(false);
          }}
          onFocus={() => {
            handlerFocused(true);
            handlerSelectedAirport(false);
            handleFocus();
          }}
          readOnly={isReadOnly}
          // onBlur={() => !isHovered && handlerFocused(false)}
        />

        {(inputValue || value) && (
          <div className="div-icon-clean" onClick={() => onClearInput()}>
            <Icon iconName="close-x" iconColor="#E95582" width={20} />
          </div>
        )}
      </div>

      {/* {isFocused && inputValue && suggestions.length > 0 && ( */}
      {!isSelectedAirport &&
        isFocused &&
        inputValue &&
        suggestions.length > 0 &&
        !isDesableSearchResults && (
          <div
            ref={divRef}
            className={
              isMobileModal
                ? 'div-inputs-search-results-mobile'
                : 'div-inputs-search-results'
            }
            onClick={e => e.stopPropagation()}
            // onMouseEnter={() => handlerHovered(true)}
            // onMouseLeave={() => handlerHovered(false)}
          >
            {suggestions.map((item, index) => (
              <div
                key={index}
                onClick={() => selectedAirport(item)}
                className={
                  item.is_metropolitan_area
                    ? 'div-place-item'
                    : 'div-airport-item'
                }>
                {item.is_metropolitan_area ? (
                  <div>
                    <SlLocationPin className="icon-place" />
                  </div>
                ) : (
                  <div>
                    <PiAirplaneTiltFill className="icon-airport" />
                  </div>
                )}
                <label className="text-place-airport">{`${item.city_name} - ${item.airport_name} (${item.airport_code}), ${item.country_name}`}</label>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

InputSearch.defaultProps = {
  classNameCSS: 'input-search',
  initialValue: null,
  isDesableSearchResults: false,
  isMobileModal: false,
  isReadOnly: false,
  colorIcon: '#000000',
  autoFocus: true,
};

export default InputSearch;
