import React, {useEffect, useState} from 'react';
import './ProgressBar.css';
import * as IconLibrary from 'react-icons/fi';
import Api from '../../../services/api';
import {useDispatch, useSelector} from 'react-redux';
import {OwnProps} from './types';

const progressUpdate = (curretnPogress: number) => {
  const progressElement = document.getElementById('progress');

  if (progressElement) {
    progressElement.style.width = `${curretnPogress}%`;
  }
};

function ProgressBar({curretnPogress}: OwnProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    progressUpdate(curretnPogress);
  }, [curretnPogress]);

  return (
    <div className="progress-bar">
      {curretnPogress > -1 && (
        <div className="div-percent-status">
          <samp className="text-percent">{`${curretnPogress}%`}</samp>
        </div>
      )}

      {curretnPogress < 100 && (
        <div className="div-search-status">
          <samp className="text-search-status">
            {'Buscando passagens aéreas...'}
          </samp>
        </div>
      )}
      <div className="progress striped" id="progress"></div>
    </div>
  );
}

ProgressBar.defaultProps = {};

export default ProgressBar;
