function setLocalStorageWithExpiration<T>(
  key: string,
  value: T,
  minutes: number,
): void {
  const now = new Date();
  const expirationTime = new Date(now.getTime() + minutes * 60000);
  const data = {
    value: value,
    expirationTime: expirationTime.getTime(),
  };

  localStorage.setItem(key, JSON.stringify(data));
}

function getLocalStorageWithExpiration<T>(key: string): T | null {
  const dataString = localStorage.getItem(key);

  if (!dataString) {
    return null;
  }

  const data = JSON.parse(dataString);
  const now = new Date();

  if (now.getTime() > data.expirationTime) {
    localStorage.removeItem(key);
    return null;
  }

  return data.value;
}

export {setLocalStorageWithExpiration, getLocalStorageWithExpiration};
