import React, {useEffect, useState} from 'react';
import './SelectButton.css';
import 'react-range-slider-input/dist/style.css';
import {OwnProps} from './types';
import Icon from '../../Icon';
import CheckBox from '../CheckBox';

function SelectButton({
  label,
  isChecked,
  onClick,
  onChange,
  sizeSelectButton,
  selectButtonBackgroundColor,
  selectButtonUncheckedBackgroundColor,
  checkedIconColor2,
  unCheckedIconColor2,
  withCheckBox,
  iconName,
}: OwnProps) {
  const [isCheckedState, handlerCheckedState] = useState<boolean>(false);

  // useEffect(() => {
  //   handlerCheckedState(!isCheckedState);
  // }, [isChecked]);

  return (
    <div
      className={'div-select-button'}
      style={{
        minWidth: sizeSelectButton ?? 'fit-content',
        backgroundColor: isChecked
          ? selectButtonBackgroundColor
          : selectButtonUncheckedBackgroundColor,
      }}
      onClick={() => onClick && onClick()}>
      {withCheckBox && <CheckBox isChecked={isChecked} size={18} />}
      {iconName && (
        <Icon
          iconName={iconName}
          width={22}
          iconColor={
            isChecked
              ? selectButtonUncheckedBackgroundColor
              : selectButtonBackgroundColor
          }
        />
      )}
      <span
        className={'text-select-button'}
        style={{
          color: isChecked
            ? selectButtonUncheckedBackgroundColor
            : selectButtonBackgroundColor,
        }}>
        {label}
      </span>
    </div>
  );
}

SelectButton.defaultProps = {
  isChecked: false,
  selectButtonBackgroundColor: '#00688D',
  selectButtonUncheckedBackgroundColor: '#FFFFFF',
  checkedIconColor2: '#00688D',
  unCheckedIconColor2: '#FFFFFF',
};

export default SelectButton;
