import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';

import {RootState} from 'StoreTypes';
import {LayoutsState} from './types';

/** Initial state */
export const INITIAL_STATE: LayoutsState = {
  searchHeaderWidth: 0,
};

/** Action creators */
export const setSearchHeaderWidth = createAction<{
  searchHeaderWidth: number;
}>('SET_SEARCH_HEADER_WIDTH');

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [setSearchHeaderWidth.type]: (state, action) => ({
    ...state,
    offersTipsPosts: action.payload.searchHeaderWidth,
  }),
});

export const layoutsSagas = [];

/** Selectors */
const rootSelector = (state: RootState): LayoutsState => state.layouts;

export const getSearchHeaderWidth = createSelector(
  [rootSelector],
  layouts => layouts.searchHeaderWidth,
);
