function parseCrossSellData(
  originAirport: string,
  destinationAirport: string,
  departureDate: string,
  returnDate: string | null,
  quantityOfAdults: string,
  quantityOfChildrens: string,
  quantityOfBabies: string,
): string {
  const departureDateParse = departureDate.replace(/-/g, '');
  const returnDateParse = returnDate ? returnDate.replace(/-/g, '') : '-';

  const params = `${originAirport}/${destinationAirport}/${departureDateParse}/${returnDateParse}/${quantityOfAdults}/${quantityOfChildrens}/${quantityOfBabies}`;

  console.log('params = ', params);
  return params;
}

export {parseCrossSellData};
