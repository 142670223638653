import React, {useEffect, useState} from 'react';
import './FooterModal.css';
import * as IconLibrary from 'react-icons/fi';
import {FiX} from 'react-icons/fi';
import Api from '../../../services/api';
import {useDispatch, useSelector} from 'react-redux';
import {OwnProps} from './types';
import ViewportChecker from '../../../core/helpers/ViewPort';
import Icon from '../../Icon';

function FooterModal({
  title,
  iconName,
  description,
  className,
  textClassName,
  children,
  footerElement,
  onClose,
  height,
  iconNameOld,
  titleIconColor,
  iconAboutDescription,
}: OwnProps) {
  const IconComponent = IconLibrary[iconNameOld as keyof typeof IconLibrary];

  const isMobile = ViewportChecker();

  return (
    <div className={'div-footer-modal-background'}>
      <div
        className={'div-footer-modal'}
        style={{height: height ? `${height}%` : 'auto'}}>
        <div>
          <div className={'div-footer-modal-header'}>
            <div className={'div-footer-modal-header-icon'}>
              {iconNameOld && (
                <IconComponent className="footer-modal-header-icon" />
              )}
              {iconName && (
                <Icon
                  iconName={iconName}
                  width={30}
                  iconColor={titleIconColor}
                />
              )}
            </div>
            <div className={'div-footer-modal-header-mid'}>
              <span className="footer-modal-header-title">{title}</span>
              <div className="div-footer-modal-header-description">
                <span className="footer-modal-header-description">
                  {description}
                </span>
                {iconAboutDescription}
              </div>
            </div>
            <div className={'div-footer-modal-header-close-icon'}>
              <FiX
                className="footer-modal-header-icon"
                onClick={() => onClose()}
              />
            </div>
          </div>
          <div className={'div-footer-modal-mid'}> {children}</div>

          {footerElement && (
            <div className={'div-footer-modal-bootom'}>{footerElement}</div>
          )}
        </div>
      </div>

      {/* <p>O tamanho da viewport é menor que 769px? {isMobile ? 'Sim' : 'Não'}</p>
       */}
      {/* <>
        {iconName && <IconComponent className="icon-button" />}
        <span className={textClassName}>{title}</span>
      </> */}
    </div>
  );
}

FooterModal.defaultProps = {
  iconName: null,
  className: 'div-button',
  textClassName: 'text-title-button',
  height: 'auto',
  titleIconColor: '#666666',
};

export default FooterModal;
