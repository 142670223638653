/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import './Toast.css';
import * as IconLibrary from 'react-icons/fi';
import {OwnProps} from './types';
import Icon from '../../Icon';

function Toast({
  onClose,
  semanticType,
  visibleTime,
  message,
  icon,
  isVisible,
}: OwnProps) {
  const [progress, setProgress] = useState(100);
  const [show, setShow] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!isVisible) return;
    setShow(true);
    const intervalDuration = 100;
    const totalIntervals = (visibleTime * 1000) / intervalDuration;
    const decrementPerInterval = 100 / totalIntervals;

    intervalRef.current = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress - decrementPerInterval;
        if (newProgress <= 0) {
          clearInterval(intervalRef.current);

          onCloseToast();
          return 0;
        }
        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isVisible]);

  const onCloseToast = () => {
    setTimeout(() => {
      clearInterval(intervalRef.current);
      onClose();
      setShow(false);
      setProgress(100);
    }, 100);
  };

  const getSemanticColor = () => {
    const colorMap = {
      error: '#fa6969',
      success: '#7bc683',
      warning: '#eae725',
    };

    return colorMap[semanticType];
  };

  return (
    <div
      className={
        isVisible
          ? 'div-toast'
          : progress < 100
          ? 'div-toast hide'
          : 'div-toast none'
      }
      style={{backgroundColor: getSemanticColor()}}>
      <div className="div-toast-close-icon" onClick={() => onCloseToast()}>
        <Icon iconName="close-x" width={20} iconColor="#ffffff" />
      </div>
      <div className="toast-content">
        <span>{message}</span>
      </div>
      <div className="toast-progress-bar" style={{width: `${progress}%`}}></div>
    </div>
  );
}

Toast.defaultProps = {};

export default Toast;
