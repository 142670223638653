import {
  createAction,
  createReducer,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import {RootState} from 'StoreTypes';
import {CrossSellState} from './types';
import Api from '../../services/api';
import Cookies from 'js-cookie';
import {
  getLocalStorageWithExpiration,
  setLocalStorageWithExpiration,
} from '../../core/helpers/Cookie';

/** Initial state */
export const INITIAL_STATE: CrossSellState = {
  offersTipsPosts: [],
};

/** Action creators */
export const requestBlogPosts = createAction<{
  postType: string;
  perPage: string;
  paged: string;
  orderBy: string;
}>('RESQUEST_BLOG_POSTS');
export const successResquestBlogPosts = createAction(
  'SUCCESS_RESQUEST_BLOG_POSTS',
);
export const errorResquestBlogPosts = createAction('ERROR_RESQUEST_BLOG_POSTS');

/** Reducer */
export default createReducer(INITIAL_STATE, {
  [requestBlogPosts.type]: (state, action) => ({
    ...state,
    offersTipsPosts: [],
  }),
  [successResquestBlogPosts.type]: (state, action) => ({
    ...state,
    offersTipsPosts: action.payload.offersTipsPosts,
  }),
  [errorResquestBlogPosts.type]: state => ({
    ...state,
    offersTipsPosts: [],
  }),
});

/** Sagas */

export function* requestBlogPostsSaga(
  action: PayloadAction<{
    postType: string;
    perPage: string;
    paged: string;
    orderBy: string;
  }>,
): Generator {
  let response: any;

  const keyName = 'getOffrersTips';

  const localValue = getLocalStorageWithExpiration(keyName);

  if (localValue) {
    console.log('localValue encontrado!');
    response = localValue;
  } else {
    console.log('localValue não encontrado!');

    response = yield call(Api.getOffrersTips, {
      ...action.payload,
    });

    const timeToLive = 3; // 3 minutes
    setLocalStorageWithExpiration(keyName, response, timeToLive);
  }

  try {
    yield put({
      type: successResquestBlogPosts.type,
      payload: {offersTipsPosts: response?.data.posts},
    });
  } catch (error) {
    yield put({
      type: errorResquestBlogPosts.type,
      payload: {},
    });
  }
}

export const offersTipsSagas = [
  takeLatest(requestBlogPosts.type, requestBlogPostsSaga),
];

/** Selectors */
const rootSelector = (state: RootState): CrossSellState => state.offersTips;

export const getOffersTipsPosts = createSelector(
  [rootSelector],
  offersTips => offersTips.offersTipsPosts,
);
