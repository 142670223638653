import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import createSagaMiddleware, {Saga, SagaIterator} from 'redux-saga';
import {logger} from '../core/middlewares/logger';
import rootReducer from './rootReducer';
import sagas from './rootSaga';

const sagaMiddleware = createSagaMiddleware({});

const middleware: any = false
  ? [...getDefaultMiddleware({thunk: false}), sagaMiddleware, logger]
  : [...getDefaultMiddleware({thunk: false}), sagaMiddleware];

const consfiguredStore = configureStore({
  reducer: rootReducer as any,
  middleware,
  devTools: false,
});
type EnhancedStoreType = typeof consfiguredStore & {
  dispatch(action: Saga): SagaIterator;
};

const store: EnhancedStoreType | any = consfiguredStore;

export type AppDispatch = typeof consfiguredStore.dispatch;

export {store};

sagaMiddleware.run(sagas);
