import React, {useEffect, useState} from 'react';
import './Button.css';
import * as IconLibrary from 'react-icons/fi';
import Api from '../../../services/api';
import {useDispatch, useSelector} from 'react-redux';
import {OwnProps} from './types';
import ViewportChecker from '../../../core/helpers/ViewPort';
function Button({
  title,
  iconName,
  className,
  textClassName,
  onClick,
}: OwnProps) {
  const IconComponent = IconLibrary[iconName as keyof typeof IconLibrary];
  const isMobile = ViewportChecker();

  return (
    <button className={className} onClick={() => onClick()}>
      <>
        {!isMobile && <span className={textClassName}>{title}</span>}
        {iconName && <IconComponent className="icon-button" />}
        {isMobile && <span className={textClassName}>{title}</span>}
      </>
    </button>
  );
}

Button.defaultProps = {
  iconName: null,
  className: 'div-button',
  textClassName: 'text-title-button',
};

export default Button;
