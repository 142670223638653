import * as diacritics from 'diacritics';

function formatStringToSlug(input: string): string {
  const withoutAccentsAndLowercase = removeAccents(input).toLowerCase().trim();

  const slug = withoutAccentsAndLowercase.replace(/\s+/g, '-');

  return slug;
}

function parseSearchParams(url: string): {
  [key: string]: string | number | string[];
} {
  const urlObj = new URL(url);

  const paramNameMapping: {[key: string]: string} = {
    'origin-airport': 'originAirport',
    'destination-airport': 'destinationAirport',
    'departure-date': 'departureDate',
    'return-date': 'returnDate',
    'quantity-of-adults': 'quantityOfAdults',
    'quantity-of-children': 'quantityOfChildrens',
    'quantity-of-babies': 'quantityOfBabies',
    'total-expected-requests': 'totalExpectedRequests',
  };

  const searchIntent: {[key: string]: string | number | string[]} = {};

  const subtractOneMonth = (dateStr: string): string => {
    const date = new Date(dateStr);
    date.setMonth(date.getMonth() - 1);
    const adjustedMonth = date.getMonth().toString().padStart(2, '0'); // Ajusta o mês para 2 dígitos
    const adjustedDay = date.getDate().toString().padStart(2, '0'); // Ajusta o dia para 2 dígitos
    return `${date.getFullYear()}-${adjustedMonth}-${adjustedDay}`;
  };

  urlObj.searchParams.forEach((value, key) => {
    const paramName = paramNameMapping[key] || key;

    if (paramName === 'departureDate' || paramName === 'returnDate') {
      searchIntent[paramName] = value ? value.split(',') : [];
      // const dates = value ? value.split(',') : [];
      // searchIntent[paramName] = dates.map(subtractOneMonth);
    } else if (
      paramName === 'quantityOfAdults' ||
      paramName === 'quantityOfBabies' ||
      paramName === 'quantityOfChildrens'
    ) {
      searchIntent[paramName] = value ? Number(value) : 0;
    } else {
      searchIntent[paramName] = value;
    }
  });

  // console.log('searchIntent =', JSON.stringify(searchIntent));
  console.log('searchIntent =', searchIntent);

  return searchIntent;
}

function isSearchUrl(url: string): boolean {
  const urlObj = new URL(url);

  const requiredParams = [
    'origin-airport',
    'destination-airport',
    'departure-date',
    // 'total-expected-requests',
  ];
  for (const param of requiredParams) {
    if (!urlObj.searchParams.has(param)) {
      return false;
    }
  }

  return true;
}

const removeAccents = (str: string) => {
  return diacritics.remove(str);
};

export {formatStringToSlug, parseSearchParams, removeAccents, isSearchUrl};
