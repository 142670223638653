/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef} from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './FloatContainer.css';
import {OwnProps} from './types';

function FloatContainer({
  onClose,
  onConfirm,
  onClickOut,
  isVisible,
  children,
  width,
  height,
  left,
  right,
  isSizePixel,
}: OwnProps) {
  const divRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        onClickOut && onClickOut();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef, isVisible]);

  return (
    <div
      ref={divRef}
      className="div-container-float-container"
      style={{
        width: isSizePixel ? width : `${width}%`,
        left: left,
      }}>
      {children}
    </div>
  );
}

FloatContainer.defaultProps = {
  isVisible: false,
  isMoboile: false,
  width: 100,
  height: 100,
  isSizePixel: false,
};

export default FloatContainer;
