import React, {useEffect, useState} from 'react';
import './CheckBox.css';
import 'react-range-slider-input/dist/style.css';
import {OwnProps} from './types';
import Icon from '../../Icon';

function CheckBox({
  size,
  label,
  isChecked,
  onClick,
  onChange,
  isBlocked,
  checkedBackgroundColor,
  uncheckedBackgroundColor,
  checkedIconColor,
  unCheckedIconColor,
}: OwnProps) {
  return (
    <div
      className={'div-checkbox'}
      style={{
        width: size,
        border: isBlocked ? '2px solid #828282' : '2px solid #00688D',
        backgroundColor: isBlocked
          ? '#828282'
          : isChecked
          ? checkedBackgroundColor
          : uncheckedBackgroundColor,
      }}
      onClick={() => !isBlocked && onClick && onClick()}>
      {isChecked && (
        <Icon
          iconName="check"
          width={size && size - 4}
          iconColor={isChecked ? checkedIconColor : unCheckedIconColor}
        />
      )}
    </div>
  );
}

CheckBox.defaultProps = {
  isChecked: false,
  size: 20,
  checkedBackgroundColor: '#FFFFFF',
  uncheckedBackgroundColor: '#FFFFFF',
  checkedIconColor: '#00688D',
  unCheckedIconColor: '#FFFFFF',
};

export default CheckBox;
