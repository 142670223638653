import axios from 'axios';
import {getServer} from '../endpointManager';

/**
 * Voopter Search Flights API configuration
 */
const API_SEARCH_FLIGHTS = axios.create({
  timeout: 300000,
  responseType: 'json',
});

/**
 * Voopter Cross Sell API configuration
 */
const API_CROSS_SELL = axios.create({
  timeout: 300000,
  responseType: 'json',
});

/**
 * Voopter Cross Sell API configuration
 */
const API_OFFERS_TIPS = axios.create({
  timeout: 300000,
  responseType: 'json',
});

API_SEARCH_FLIGHTS.interceptors.request.use(config => {
  config.baseURL =
    process.env.REACT_APP_IS_LOCAL === 'true'
      ? `http://${getServer()}`
      : `https://${getServer()}`;
  console.log(`WebSocket Server: ${config.baseURL}`);
  config.headers.Authorization = '$3394@gjfy^W$W2Z@Rmf!2!s@aA&%*6h';
  config.headers['Accept-Encoding'] = 'gzip, deflate, br';
  return config;
});

// API_SEARCH_FLIGHTS.interceptors.request.use(config => {
//   const requestConfig = config;
//   // requestConfig.baseURL = 'http://localhost:3000';
//   // requestConfig.baseURL = 'https://rancher.voopter.com.br';
//   // requestConfig.baseURL = 'https://flightsearch-api-staging.voopter.com.br';
//   requestConfig.baseURL = 'https://flightsearch-api.voopter.com.br';

//   requestConfig.headers.Authorization = '$3394@gjfy^W$W2Z@Rmf!2!s@aA&%*6h';
//   return requestConfig;
// });

API_CROSS_SELL.interceptors.request.use(config => {
  const requestConfig = config;
  requestConfig.baseURL = 'https://cross.voopter.com.br';

  return requestConfig;
});

API_OFFERS_TIPS.interceptors.request.use(config => {
  const requestConfig = config;
  requestConfig.baseURL = 'https://blog.voopter.com.br';

  return requestConfig;
});

export {API_SEARCH_FLIGHTS, API_CROSS_SELL, API_OFFERS_TIPS};
