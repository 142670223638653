/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef} from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './Skeleton.css';
import {OwnProps} from './types';

function Skeleton({
  width,
  height,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  borderRadius,
}: OwnProps) {
  return (
    <div
      className="div-skeleton"
      style={{
        width: `${width}%`,
        height: height,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        borderRadius: borderRadius,
      }}
    />
  );
}

Skeleton.defaultProps = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
  borderRadius: 0,
};

export default Skeleton;
