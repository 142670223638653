import icons from './iconData.json';

import {OwnProps} from './types';

function Icon({iconName, width, iconColor, href, onClick}: OwnProps) {
  return (
    <div onClick={() => onClick && onClick()}>
      <a href={href}>
        <svg width={`${width}`} viewBox="0 0 1024 1024">
          <path d={`${icons[iconName]}`} fill={`${iconColor}`} />
        </svg>
      </a>
    </div>
  );
}

Icon.defaultProps = {
  iconName: 'calendar',
  width: 30,
  iconColor: '#000000',
};

export default Icon;
