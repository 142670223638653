/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import './CountButton.css';
import * as IconLibrary from 'react-icons/fi';
import {OwnProps} from './types';
import Icon from '../../Icon';

function CountButton({
  oneChange,
  desablePlus,
  desableLess,
  value,
  maxValue,
  minValue,
}: OwnProps) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    oneChange(currentValue);
  }, [currentValue]);

  return (
    <div className="div-count-button">
      <div
        className="div-one-plus"
        onClick={() =>
          currentValue > minValue && setCurrentValue(currentValue - 1)
        }>
        <Icon iconName="less" width={20} iconColor="#666666" />
      </div>
      <div className="div-count-button-value">
        <span className="text-count-button-value">{currentValue}</span>
      </div>
      <div
        className="div-one-plus"
        onClick={() =>
          currentValue < maxValue && setCurrentValue(currentValue + 1)
        }>
        <Icon iconName="plus" width={20} iconColor="#666666" />
      </div>
    </div>
  );
}

CountButton.defaultProps = {
  iconName: null,
  className: 'div-button',
  textClassName: 'text-title-button',
  maxValue: 99,
  minValue: 0,
};

export default CountButton;
