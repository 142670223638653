import { all } from "redux-saga/effects";

// Import sagas
import { crossSellSagas } from "./crossSell";
import { flightsSagas } from "./flights";
import { offersTipsSagas } from "./offersTips";
import { layoutsSagas } from "./layouts";

export default function* rootSaga(): Generator {
  yield all([
    // Combine sagas
    ...crossSellSagas,
    ...flightsSagas,
    // ...offersTipsSagas,
    ...layoutsSagas,
  ]);
}
