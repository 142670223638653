import Skeleton from '..';
import './Skeletons.css';

export const FlightCardMobileSkeleton = () => (
  <div className="div-flight-card-skeleton-mobile">
    {Array.from({length: 2}).map((_, index) => (
      <div className="div-flight-card-skeleton-item">
        <Skeleton width={12} height={20} borderRadius={100} marginLeft={0} />
        <Skeleton width={15} height={40} borderRadius={10} marginLeft={10} />
        <Skeleton width={15} height={40} borderRadius={10} marginLeft={20} />
        <Skeleton width={18} height={10} borderRadius={100} marginLeft={10} />
        <Skeleton
          width={15}
          height={40}
          borderRadius={10}
          marginLeft={10}
          marginRight={0}
        />
      </div>
    ))}

    <Skeleton width={90} height={50} borderRadius={15} marginLeft={0} />
  </div>
);
