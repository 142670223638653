import React, {useState, useEffect} from 'react';

function ViewportChecker() {
  const [isMobileViewport, setIsMobileViewport] = useState(
    window.innerWidth < 1055,
  );

  useEffect(() => {
    function handleResize() {
      setIsMobileViewport(window.innerWidth < 1055);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileViewport;
}

export default ViewportChecker;
