import React, { useEffect, useState } from "react";
import "./Header.css";
import headerLogo from "../../../assets/logo-voopter.svg";
import { FiAlignJustify, FiX } from "react-icons/fi";

function Header() {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);

  useEffect(() => {
    setIsShowMobileMenu(false);
  }, []);

  const toggleMobileMenu = () => {
    return new Promise<void>((resolve) => {
      setIsShowMobileMenu(false);

      resolve();
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 95) {
        setIsHeaderHidden(true);
      } else {
        setIsHeaderHidden(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`header ${isHeaderHidden ? "hidden" : "show"}`}>
        <div className={`div-mobile-menu ${isShowMobileMenu ? "show" : ""}`}>
          {isShowMobileMenu && (
            <>
              <FiX
                className="icon-mobile-close-menu"
                onClick={() => setIsShowMobileMenu(false)}
              />
              <div className="div-mobile-content-menu">
                <ul className="nav-list-mobile">
                  <li
                    className="nav-item-mobile"
                    onClick={() => setIsShowMobileMenu(false)}
                  >
                    Pesquisa de Passagens
                  </li>
                  {/* <li
                    className="nav-item-mobile"
                    onClick={async () => {
                      await toggleMobileMenu();
                      window.location.href =
                        'https://voopter.com.br/passagens-aereas-promocionais';
                    }}>
                    Promoções e Dicas
                  </li> */}
                  <li
                    className="nav-item-mobile"
                    onClick={async () => {
                      await toggleMobileMenu();

                      window.location.href =
                        "https://voopter.com.br/passagens-aereas-promocionais";
                    }}
                  >
                    Passagens Aéreas Baratas
                  </li>
                  <li
                    className="nav-item-mobile"
                    onClick={() =>
                      (window.location.href = "https://voopter.com.br/alertas")
                    }
                  >
                    Alertas
                  </li>
                  <li
                    className="nav-item-mobile"
                    onClick={() =>
                      (window.location.href =
                        "https://www.booking.com/index.html?aid=807471")
                    }
                  >
                    Hotéis
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div
          className="div-button-mobile-menu"
          onClick={() => setIsShowMobileMenu(true)}
        >
          <FiAlignJustify className="icon-mobile-menu" />
        </div>

        <div className="header-content">
          <div className="div-logo">
            <img
              className="div-logo-img"
              src={headerLogo}
              alt="Encontre passagens aéreas baratas com o Voopter"
              onClick={() => (window.location.href = window.location.origin)}
            />
          </div>
          <div className="div-options">
            <ul className="nav-list">
              <li className="nav-item">Pesquisa de Passagens</li>
              <li
                className="nav-item"
                onClick={() =>
                  (window.location.href =
                    "https://voopter.com.br/ofertas-e-dicas")
                }
              >
                Promoções e Dicas
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  window.location.href =
                    "https://voopter.com.br/passagens-aereas-promocionais";
                }}
              >
                Passagens Aéreas Baratas
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  (window.location.href = "https://voopter.com.br/alertas")
                }
              >
                Alertas
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  (window.location.href =
                    "https://www.booking.com/index.html?aid=807471")
                }
              >
                Hotéis
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
