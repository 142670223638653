import React, {useEffect, useState} from 'react';
import './InlineBanner.css';
import {OwnProps} from './types';
import OfferButton from '../Components/OfferButton';

function InlineBanner({inlineData}: OwnProps) {
  return (
    <>
      <div className="div-inlineBanner">
        <div className="div-inlineBanner-image-content">
          <div className="div-inlineBanner-image">
            <img
              className="img-inlineBanner"
              src={`https:${inlineData?.image}`}
              alt="Encontre passagens aéreas baratas com o Voopter"
            />
          </div>
          <div className="div-inlineBanner-content">
            <div className="title-inlineBanner">
              <span className="title-text-inlineBanner">{`${inlineData?.title}`}</span>
            </div>
            <div className="desciption-inlineBanner">
              <span className="desciption-text-inlineBanner">{`${inlineData?.description}`}</span>
            </div>
            {/* <div className="advertiser-inlineBanner">
              <span className="advertiser-text-inlineBanner">{`${inlineData?.advertiser}`}</span>
            </div> */}
          </div>
        </div>

        <div className="div-inlineBanner-button">
          <OfferButton
            labael="VER OFERTA"
            onClick={() => {
              window.open(inlineData?.url, '_blank');
              const event = {
                event: 'inline_click-staging',
                seller: inlineData?.id,
                route: inlineData?.url,
              };

              window.dataLayer.push(event);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default InlineBanner;
