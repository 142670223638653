import React, {useEffect, useState} from 'react';
import './ButtonGroup.css';
import * as IconLibrary from 'react-icons/fi';
import Api from '../../../services/api';
import {useDispatch, useSelector} from 'react-redux';
import {OwnProps} from './types';

function ButtonGroup({
  title,
  iconName,
  className,
  textClassName,
  onClickLeftOption,
  onClickRightOption,
}: OwnProps) {
  return (
    <div className="div-button-group">
      <div
        className="div-button-group-left"
        onClick={() => onClickLeftOption()}>
        <span className="text-button-group-left">Limpar</span>
      </div>
      <div
        className="div-button-group-right"
        onClick={() => onClickRightOption()}>
        <span className="text-button-group-right">Aplicar</span>
      </div>
    </div>
  );
}

ButtonGroup.defaultProps = {
  iconName: null,
  className: 'div-button',
  textClassName: 'text-title-button',
};

export default ButtonGroup;
