import React, {useEffect, useState} from 'react';
import Lottie from 'react-lottie';
import {OwnProps} from './types';
import './Animation.css';

function Animation({
  animationData,
  isLoop,
  isStop,
  width,
  height,
  isShow,
}: OwnProps) {
  const [animation, setAnimation] = useState<any>();

  useEffect(() => {
    setAnimation(animationData);
  }, [animationData]);

  return isShow ? (
    <Lottie
      options={{
        loop: isLoop,
        autoplay: isStop,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={height}
      width={width}
      isStopped={false}
      isPaused={false}
    />
  ) : null;
}

Animation.defaultProps = {
  isLoop: true,
  isStop: false,
  isShow: true,
  width: 100,
  height: 100,
};

export default Animation;
