import React, {useEffect, useState} from 'react';
import './PassengersSelector.css';
import {OwnProps} from './types';
import Icon from '../../Icon';
import ViewportChecker from '../../../core/helpers/ViewPort';

function PassengersSelector({onClick, isRow, numberOfPassengers}: OwnProps) {
  const isMobile = ViewportChecker();

  return (
    <div
      className={
        isRow ? 'div-passengers-selector-row' : 'div-passengers-selector'
      }
      onClick={() => onClick && onClick()}>
      {numberOfPassengers.children + numberOfPassengers.babies === 0 &&
        !isRow && (
          <div className="div-left-passengers-selector-icon">
            <Icon
              iconName={'add-profile'}
              width={isMobile ? 28 : 20}
              iconColor="#00688D"
            />
          </div>
        )}

      <div
        className={
          isRow
            ? 'div-left-passengers-selector-2'
            : 'div-left-passengers-selector'
        }>
        {numberOfPassengers.children + numberOfPassengers.babies === 0 &&
        isMobile ? (
          <samp className="text-content-passengers-selector">
            {`${numberOfPassengers.adults.toString().padStart(2, '0')} ${
              numberOfPassengers.adults > 1 ? 'Adultos' : 'Adulto'
            }`}
          </samp>
        ) : (
          <div
            className={
              isRow
                ? 'div-content-passengers-selector-2'
                : 'div-content-passengers-selector'
            }>
            <samp className="text-content-passengers-selector">
              {numberOfPassengers.adults.toString().padStart(2, '0')}
            </samp>
            <Icon
              iconName={'adult'}
              width={isMobile ? 20 : 15}
              iconColor="#666666"
            />
          </div>
        )}

        {numberOfPassengers.children > 0 && (
          <div className="div-content-passengers-selector">
            <samp className="text-content-passengers-selector">
              {numberOfPassengers.children.toString().padStart(2, '0')}
            </samp>
            <Icon
              iconName={'child'}
              width={isMobile ? 20 : 15}
              iconColor="#666666"
            />
          </div>
        )}

        {numberOfPassengers.babies > 0 && (
          <div className="div-content-passengers-selector">
            <samp className="text-content-passengers-selector">
              {numberOfPassengers.babies.toString().padStart(2, '0')}
            </samp>
            <Icon
              iconName={'baby'}
              width={isMobile ? 20 : 15}
              iconColor="#666666"
            />
          </div>
        )}
      </div>
    </div>
  );
}

PassengersSelector.defaultProps = {
  iconName: null,
  className: 'div-button',
  textClassName: 'text-title-button',
};

export default PassengersSelector;
